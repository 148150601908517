import React, {useEffect, useState} from 'react'
import {Checkbox, FormControlLabel, TableCell, TableRow, TextField} from '@material-ui/core'

import {ICrawling, IGroup, IUser} from 'common'
import {Admin} from '../Admin'
import api from '../../services/api'
import styles from "./styles.scss"
import {Autocomplete} from "@material-ui/lab";

interface CrawlingEditDialogProps {
    onValidItem: (crawling: Partial<ICrawling>) => void
    crawling: ICrawling | null
    // _id -> group
    groups: Map<string, IGroup>
    users: Map<string, IUser>
}

function CrawlingEditDialog(props: CrawlingEditDialogProps) {
    const [hashtag, setHashtag] = useState(props.crawling?.hashtag || '')
    const [active, setActive] = useState(props.crawling?.active || false)
    const [dailyQuota, setDailyQuota] = useState(props.crawling?.dailyQuota || 1000)
    const [targetGroupId, setTargetGroupId] = useState((props.crawling?.group?._id || '') as string)
    const [ownerId, setOwnerId] = useState((props.crawling?.owner?._id || '') as string)

    function toArray<T>(map: Map<string, T>, sortKey: (t: T) => string): T[] {
        return Array.from(map.values())
            .sort((a, b) => sortKey(a).localeCompare(sortKey(b)))
    }

    const setCrawlingValidity = () => {
        if (hashtag.length > 0 && dailyQuota > 0 && targetGroupId.length > 0 && ownerId.length > 0) {
            const group = props.groups.get(targetGroupId)
            if (group === undefined) {
                throw new Error(`Could not find group with id ${targetGroupId}`)
            }

            const owner = props.users.get(ownerId)
            if (owner === undefined) {
                throw new Error(`Could not find owner with id ${ownerId}`)
            }

            const crawling = {
                ...props.crawling,
                hashtag,
                dailyQuota,
                active,
                group,
                owner,
                updated_at: props.crawling?.updated_at || new Date()
            }

            props.onValidItem(crawling)
        }
    }

    useEffect(setCrawlingValidity, [hashtag, active, dailyQuota, targetGroupId, ownerId])

    const changeActive = (e: React.ChangeEvent<HTMLInputElement>) => {
        setActive(e.target.checked)
    }

    return <div className={styles.form}>
        <TextField
            type='text'
            value={hashtag}
            label='Name'
            margin='normal'
            placeholder='adalong'
            required={true}
            onChange={(e) => setHashtag(e.target.value)}
        />
        <FormControlLabel
            control={<Checkbox
                checked={active}
                onChange={changeActive}
                />}
            label='Active'
        />
        <TextField
            type='number'
            value={dailyQuota}
            label='Daily Quota'
            margin='normal'
            placeholder='1000'
            required={true}
            onChange={(e) => setDailyQuota(parseInt(e.target.value))}
        />
        <Autocomplete
            value={props.crawling?.group}
            options={toArray(props.groups, (g) => g.name)}
            getOptionLabel={(g) => g.name}
            renderInput={(params) => <TextField {...params} label='Group' required={true}/>}
            onChange={(_e, g) => setTargetGroupId(g?._id || '')}
        />
        <Autocomplete
            value={props.crawling?.owner}
            options={toArray(props.users, (u) => u.email)}
            getOptionLabel={(u) => u.email}
            renderInput={(params) => <TextField {...params} label='Owner' required={true}/>}
            onChange={(_e, u) => setOwnerId(u?._id || '')}
        />
    </div>
}

function Crawlings() {
    // _id -> group
    const [groups, setGroups] = useState<Map<string, IGroup>>(new Map())
    const [users, setUsers] = useState<Map<string, IUser>>(new Map())
    const displayHeader =
        <TableRow>
            <TableCell key='1'>Hashtag</TableCell>
            <TableCell key='2'>Target Group</TableCell>
            <TableCell key='3'>Daily Quota</TableCell>
            <TableCell key='4'>Owner</TableCell>
            <TableCell key='5'>Owner Group</TableCell>
            <TableCell key='6'>Is Active?</TableCell>
            <TableCell/>
        </TableRow>

    function groupById<T>(ts: (T & {_id?: string})[]) { // HUM : Map<string, T>
        const res = new Map<string, T>()
        for (const t of ts) {
            t._id && res.set(t._id, t)
        }
        return res
    }

    const fillGroups = (): Promise<void> => {
        return api.getGroups({
            filters: {
                name: ""
            },
            options: {
                limit: 1000
            }
        }).then((res) => setGroups(groupById(res.groups)))
    }

    const fillUsers = (): Promise<void> => {
        return api.getUsers({
            filters: {
                name: ""
            },
            options: {
                limit: 1000
            }
        }).then((res) => setUsers(groupById(res.users)))
    }

    useEffect(() => {
        Promise.all([fillGroups(), fillUsers()])
            .catch((e) => {
                throw new Error(`error while filling groups or users: ${e}`)
            })
    }, [])

    const save = (crawling: Partial<ICrawling>): Promise<void> => {
        const payload = {
            ...crawling,
            owner: crawling.owner?._id,
            group: crawling.group?._id,
        }
        return api.addOrUpdateCrawling(payload as Partial<ICrawling>).then()
    }

    const create: (crawling: Partial<ICrawling>) => Promise<void> = save

    const update: (crawling: Partial<ICrawling>) => Promise<void> = save

    const search = (input: string): Promise<ICrawling[]> => {
        return api.getCrawlings(input)
    }

    const remove = (crawling: ICrawling): Promise<void> => {
        if (!crawling._id) {
            return Promise.reject(new Error('Cannot delete crawling without an _id'))
        }
        return api.deleteCrawling(crawling).then()
    }

    const displayLine = (crawling: ICrawling): JSX.Element[] => {
        if (groups.size * users.size === 0) {
            return []
        }

        const userGroup = groups.get(crawling.owner.group as unknown as string)

        if (userGroup === undefined) {
            throw new Error(`could not find owner group for ${crawling.owner.email}!`)
        }
        return [
            <TableCell key='1'>{crawling.hashtag}</TableCell>,
            <TableCell key='2'>{crawling.group?.name}</TableCell>,
            <TableCell key='3'>{crawling.dailyQuota}</TableCell>,
            <TableCell key='4'>{crawling.owner.email}</TableCell>,
            <TableCell key='5'>{userGroup.name}</TableCell>,
            <TableCell key='6'>{crawling.active ? 'Yes' : 'No'}</TableCell>,
        ]
    }

    const displayEditWindow = (crawling: ICrawling | null, onValidItem: (line: Partial<ICrawling>) => void): JSX.Element => {
        return <CrawlingEditDialog
            onValidItem={onValidItem}
            crawling={crawling}
            groups={groups}
            users={users}
        />
    }

    return <Admin<ICrawling>
        title='Crawlings'
        displayHeader={displayHeader}
        create={create}
        update={update}
        search={search}
        remove={remove}
        getId={(crawling) => crawling._id || 'unknown'}
        displayLine={displayLine}
        displayEditWindow={displayEditWindow}
    />
}

export default Crawlings
