declare var __ENV__: any;

const env = __ENV__;

export const API_URL = env.API_URL;

export const PAGES: { [key: string]: string[] } = {
    Admin: [
        'Users',
        'Administrators',
        'Groups',
        'Crawling',
    ],
}