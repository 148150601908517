import React, { Component } from "react";
import * as styles from './styles.scss';

interface IProps extends React.DOMAttributes<any> {
    value: string
    active: boolean
}

class Item extends Component<IProps> {
    render() {
        return (
            <div 
                className={styles.item} 
                data-active={this.props.active}
                onClick={this.props.onClick}
            >
                {this.props.value}
            </div>
        )
    }
}

export default Item;