import React, { Component } from "react";
import { IMLPipe } from "common";
import api from "services/api";
import { OutlinedInput } from "@material-ui/core";

interface IProps {

}

interface IState {
    pipelines: IMLPipe[] 
}

// TODO:
// Ajouter un bouton pour ajouter / supprimer un pipeline

export class Pipelines extends Component <IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            pipelines: [],
        }
        this.init()
    }

    async init() {
        this.reloadPipes()
    }

    
    changeQuery() {
        this.setState({})
    }

    reloadPipes = async () => {
        let pipes = await api.getPipelines()
        this.setState({
            pipelines: pipes,
        })
    }

    render() {
        return (
            <div>
                <div>
                    <p>Machine Learning Pipelines</p>
                </div>
                {this.state.pipelines.map(pipeline => {
                    return <div>
                        <p>{pipeline.desc}</p>
                        <OutlinedInput
                            value={JSON.stringify(JSON.parse(pipeline.query), null, 4)}
                            // onChange={this.changeQuery.bind(this)}
                            labelWidth={1000}
                            multiline={true}
                            style={{width: '100%'}}
                        />
                        <p>{pipeline.steps.map(step => {
                            return <div>
                                <p>{step.desc}</p>
                                <p>{JSON.stringify(step.model, null, 4)}</p>
                            </div>
                        })}</p>
                    </div>
                })}
            </div>
        )
    }
}
