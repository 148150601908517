export default {
    en: {
        title: "Adalong - Admin",
        username: "Username",
        password: "Password",
        signIn: "Sign In",
        error: {
            invalid: "Username or password invalid",
            generic: "An error occured",
        },
    },
}