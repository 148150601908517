import React, { Component } from "react";
import {connect} from 'react-redux';
import {TStoreState} from '../../store';

import * as styles from './styles.scss';
import { CircularProgress } from "@material-ui/core";

interface StateToProps {
    loading: boolean
}

const mapStateToProps = (state: TStoreState): StateToProps => ({
    loading: state.common.appLoading,
})

type IProps = StateToProps;

class AppLoading extends Component<IProps> {
    render() {
        if (!this.props.loading) {
            return null;
        }
        return (
            <div className={styles.loading}>
                <div className={styles.progress}>
                    <CircularProgress/>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(AppLoading);