import {AdminApiTypes, IAdmin, ICollection, ICrawling, IGroup, IInstagramCreds, IMLPipe, IRightsRequest, IUser, OldApiTypes} from 'common'
import {API_URL} from '../constants'
import Cookies from 'js-cookie'

abstract class Call {
    private static async rq(path: string, data: any, method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH') {
        let is_get = method === 'GET';
        let url: any = new URL(path);

        if (is_get) url.search = (new URLSearchParams(data));

        let params: any = {
            method: method,
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': Cookies.get('XSRF-TOKEN'),
            },
            body: JSON.stringify(data),
        };
        if (is_get) delete params.body
        return await fetch(url, params)
            .then(r => {
                if (r.status === 401) {
                    console.error('[auth error]', `API -> ${path}: ${JSON.stringify(data)}`);
                    // helpers.notAuthorized();
                    throw new Error('Auth Error');
                }
                return r.json();
            })
            .then(r => {
                if (!r)
                    throw new Error('An error occured');
                if (r.err) {
                    console.error(`API <- ${path}: ${JSON.stringify(r.err)}`);
                    throw r.err;
                } else {
                    // console.log(`API <- ${path}: ${(JSON.stringify(d.res) || '').slice(0, 200)}`);
                }
                return r.res || r.result;
            });
    }
    static async get(path: string, data?: any) {
        return await this.rq(path, data, 'GET');
    }
    static async post(path: string, data?: any) {
        return await this.rq(path, data, 'POST');
    }
    static async delete(path: string, data?: any) {
        return await this.rq(path, data, 'DELETE');
    }
    static async put(path: string, data?: any) {
        return await this.rq(path, data, 'PUT');
    }
    static async patch(path: string, data?: any) {
        return await this.rq(path, data, 'PATCH');
    }
}

export default {
    async auth(body: OldApiTypes.Admin.Index.IBodyAuth): Promise<void> {
        return await Call.post(`${API_URL}/api/admin/auth`, body);
    },
    async firebaseAuth(data: { uid: string, idToken: string, has_accepted_cgu: boolean }) {
        return await Call.post(`${API_URL}/api/admin/firebaseauth`, data)
    },
    async logout(): Promise<void> {
        return await Call.post(`${API_URL}/api/admin/logout`)
    },
    async getAdmin(): Promise<OldApiTypes.User.IResponseMe> {
        return await Call.get(`${API_URL}/api/admin/admin/me`);
    },
    async getPipelines(): Promise<IMLPipe[]> {
        return await Call.post(`${API_URL}/api/admin/pipelines/find`, {});
    },
    async getUsers(body: {
        filters: {
            name: string
        }
        options: {
            skip?: number
            limit?: number
        }
    }): Promise<{ users: IUser[], total: number }> {
        return await Call.post(`${API_URL}/api/admin/user/find`, body);
    },
    async logAsUser(userId: string): Promise<string> {
        return await Call.post(`${API_URL}/api/admin/user/logas`, { userId });
    },
    async createUser(doc: any) {
        return await Call.post(`${API_URL}/api/admin/user/create`, { doc });
    },
    async updateUser(userId: string, doc: any) {
        return await Call.post(`${API_URL}/api/admin/user/update`, { userId, doc });
    },
    async removeUser(userId: string) {
        return await Call.post(`${API_URL}/api/admin/user/disable`, { userId });
    },
    async getRightsRequests(body: {
        statuses: string []
    }): Promise<{ rightRequests: Partial<IRightsRequest>[], total: number }> {
        return Call.post(`${API_URL}/api/admin/right/find`, body);
    },
    async getAdmins(body: {
        filters: {
            name: string
        }
        options: {
            skip?: number
            limit?: number
        }
    }): Promise<{ admins: Partial<IAdmin>[], total: number }> {
        return Call.post(`${API_URL}/api/admin/administrator/find`, body);
    },
    async createAdmin(doc: any) {
        return Call.post(`${API_URL}/api/admin/administrator/create`, { doc });
    },
    async removeAdmin(adminID: string) {
        return Call.post(`${API_URL}/api/admin/administrator/remove`, { adminID });
    },
    async getCollections(body: {
        filters: {
            name: string
        }
        options: {
            skip?: number
            limit?: number
        }
    }): Promise<{
        results: ICollection[]
        total: number
    }> {
        return Call.post(`${API_URL}/api/admin/collection/find`, body);
    },
    async createCollection(doc: Partial<ICollection>) {
        return Call.post(`${API_URL}/api/admin/collection/create`, { doc });
    },
    async updateCollection(params: Partial<ICollection>) {
        return Call.post(`${API_URL}/api/admin/collection/update`, params);
    },
    async removeCollection(collectionID: string) {
        return Call.post(`${API_URL}/api/admin/collection/remove`, { collectionID });
    },
    async getGroups(body: {
        filters: {
            name: string
        }
        options: {
            skip?: number
            limit?: number
        }
    }): Promise<{ groups: IGroup[], total: number }> {
        return Call.post(`${API_URL}/api/admin/group/find`, body);
    },

    async createGroup(body: AdminApiTypes.BodyCreateGroup): AdminApiTypes.ResCreateGroup {
        return Call.post(`${API_URL}/api/admin/group`, body)
    },

    async updateGroup(id: string, body: AdminApiTypes.BodyUpdateGroup): AdminApiTypes.ResUpdateGroup {
        return Call.patch(`${API_URL}/api/admin/group/${id}`, body)
    },

    async deleteGroup(id: string): Promise<void> {
        return Call.delete(`${API_URL}/api/admin/group/${id}`)
    },

    async getCrawlings(q: string) {
        return await Call.get(`${API_URL}/api/admin/crawlings/`, { q }) as ICrawling[];
    },
    async getInstagramUsers() {
        return await Call.get(`${API_URL}/api/admin/crawlings/instagramusers`) as IInstagramCreds[];
    },
    async addOrUpdateCrawling(crawling: Partial<ICrawling>) {
        return await Call.put(`${API_URL}/api/admin/crawlings/`, crawling);
    },
    async deleteCrawling(body: ICrawling) {
        return await Call.delete(`${API_URL}/api/admin/crawlings/`, body);
    },
    async getInstagramUsersHashtagUsage(creds: IInstagramCreds) {
        return await Call.get(`${API_URL}/api/admin/crawlings/instagramusersgraphusage`, { userid: creds.user_id }) as string[]
    },
    async updateGroupHashtags(body: {
        groupId: string,
        hashtags: string[]
    }): Promise<{ groups: IGroup[] }> {
        return await Call.post(`${API_URL}/api/admin/group/updatehashtags`, body);
    },
}
