import React from 'react'
import firebase from '../services/firebaseAuth'
import { Tooltip, Chip, Avatar } from '@material-ui/core'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import PersonIcon from '@material-ui/icons/Person'
import api from 'services/api';

interface State {
    user: firebase.User | null
}

interface Props {

}

export class UserPill extends React.Component<Props, State> {
    fireunsub?: firebase.Unsubscribe
    constructor(props: Props) {
        super(props)

        this.state = {
            user: firebase.auth().currentUser
        }
    }

    componentDidMount() {
        this.fireunsub = firebase.auth().onAuthStateChanged((user) => {
            this.setState({
                user
            })
        });
    }

    componentWillUnmount() {
        if (this.fireunsub) this.fireunsub()
    }

    async signOut() {
        await firebase.auth().signOut()
        await api.logout()
        window.location.href = '/'
    }

    render() {
        return (
            <div>
                {this.state.user ?
                    <Chip
                        style={{margin: 5}}
                        avatar={this.state.user.photoURL ? <Avatar src={this.state.user.photoURL} /> : <PersonIcon />}
                        label={this.state.user.displayName || ""}
                        deleteIcon={
                            <Tooltip title="Logout" aria-label="logout">
                                <LogoutIcon style={{ color: "#000" }} />
                            </Tooltip>
                        }
                        onDelete={() => this.signOut()} />
                    : <div onClick={() => this.signOut()}><LogoutIcon /></div>
                }
            </div>
        )
    }
}
