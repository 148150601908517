import React, { Component } from "react";
import {connect} from 'react-redux';
import {TStoreState} from 'store';
import SideBar from "components/SideBar";
import {setRoute} from 'services/tools';
import { IAdmin } from "common";

interface StateToProps {
    route: string,
    admin: Partial<IAdmin> | null
}

const mapStateToProps = (state: TStoreState): StateToProps => ({
    route: state.router.location.pathname.split('/')[1],
    admin: state.common.admin,
});

type IProps = StateToProps;

class SideBarContainer extends Component<IProps> {
    render() {
        if (!this.props.admin) {
            return null;
        }
        return (
            <SideBar
                active={this.props.route}
                setRoute={setRoute.bind(this)}
            />
        )
    }
}

export default connect(mapStateToProps)(SideBarContainer);