import * as ACTIONS from "./_types"
import { DispatchType } from "store";
import api from '../services/api';
import { IAdmin, IUser } from "common";

export function setAdminInfo(admin: Partial<IAdmin>) {
    return { type: ACTIONS.SET_ADMIN_INFO, admin };
}

export function setUserInfo(user: Partial<IUser> | null) {
    return { type: ACTIONS.SET_USER_INFO, user };
}

export function setAppLoaded() {
    return { type: ACTIONS.SET_APP_LOADED };
}

export function loadAdminInfo() {
    return async (dispatch: DispatchType) => {
        let { admin, user } = await api.getAdmin();
        dispatch(setAdminInfo(admin));
        dispatch(setUserInfo(user));
        return { admin, user }
    }
}