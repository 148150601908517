import React, { Component } from "react";
import * as styles from './styles.scss';
import classNames from 'classnames';
import { SnackbarContent, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { SvgIconProps } from "@material-ui/core/SvgIcon";


// import translation from './translation';
// import LocalizedStrings from 'react-localization';
// import api from "services/api";

// let lang = new LocalizedStrings(translation);

export type EVariant = 'error' | 'success' | 'warning' | 'error' | 'info';

const variantIcon: {[key in EVariant]: React.ComponentType<SvgIconProps>} = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};


interface IProps {
    variant: EVariant
    message: string
    className?: string
    onClose: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined
}

class Alert extends Component<IProps> {
    render() {
        const { className, message, onClose, variant } = this.props;
        const Icon = variantIcon[variant];

        return (
            // <div className={styles.alert} data-type={this.props.type}>
            //     {this.props.children}
            // </div>
            <SnackbarContent
                className={classNames(styles[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={styles.message}>
                        <Icon className={classNames(styles.icon, styles.iconVariant)} />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={styles.close}
                        onClick={onClose}
                    >
                        <CloseIcon className={styles.icon} />
                    </IconButton>,
                ]}
            />
        )
    }
}

export default Alert;