import React, { Component } from "react";
import * as styles from './styles.scss';

interface IProps {
    value: string
}

class Category extends Component<IProps> {
    render() {
        return (
            <div>
                <div className={styles.category}>{this.props.value}</div>
                {this.props.children}
            </div>
        )
    }
}

export default Category;