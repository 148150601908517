exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-components-Alert-styles__alert--3JbuN{margin:8px;flex-grow:1;color:#fff;display:flex;padding:6px 24px;flex-wrap:wrap;align-items:center}.src-components-Alert-styles__message--26aYl{display:flex;align-items:center}.src-components-Alert-styles__icon--11MJa{font-size:20}.src-components-Alert-styles__iconVariant--Jpvoz{opacity:.9;margin-right:15px}.src-components-Alert-styles__success--29hE9{background-color:#43a047 !important}.src-components-Alert-styles__error--3kMT6{background-color:#d32f2f !important}.src-components-Alert-styles__info--36HI3{background-color:#1976d2 !important}.src-components-Alert-styles__warning--1k_3F{background-color:#ffa000 !important}", ""]);

// Exports
exports.locals = {
	"alert": "src-components-Alert-styles__alert--3JbuN",
	"message": "src-components-Alert-styles__message--26aYl",
	"icon": "src-components-Alert-styles__icon--11MJa",
	"iconVariant": "src-components-Alert-styles__iconVariant--Jpvoz",
	"success": "src-components-Alert-styles__success--29hE9",
	"error": "src-components-Alert-styles__error--3kMT6",
	"info": "src-components-Alert-styles__info--36HI3",
	"warning": "src-components-Alert-styles__warning--1k_3F"
};