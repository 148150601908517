import * as ACTIONS from "../actions/_types"
import { IAdmin, IUser } from "common";

export const initialState = {
    appLoading: true,
    admin: null as Partial<IAdmin> | null,
    user: null as IUser | null,
};

export type TState = Readonly<typeof initialState>

export default function (state = initialState, action: any) {
    switch (action.type) {
        case ACTIONS.SET_ADMIN_INFO:
            return {
                ...state,
                admin: action.admin,
            }
        case ACTIONS.SET_USER_INFO:
            return {
                ...state,
                user: action.user,
            }
        case ACTIONS.SET_APP_LOADED:
            return {
                ...state,
                appLoading: false,
            }
        default:
            return state;
    }
}