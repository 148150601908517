exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-containers-Admin-styles__paper--1kG8A{padding:15px;overflow-x:auto}.src-containers-Admin-styles__searchbar--fmQw3{margin-top:70px;margin-bottom:20px}.src-containers-Admin-styles__results--26PbL{color:#616060 !important;margin-left:15px !important;text-decoration:bold !important}.src-containers-Admin-styles__create--tR1D5{display:inline-block;float:right;margin:70px 50px}.src-containers-Admin-styles__icon-link--2df7g{padding:0 6px;cursor:pointer}", ""]);

// Exports
exports.locals = {
	"paper": "src-containers-Admin-styles__paper--1kG8A",
	"searchbar": "src-containers-Admin-styles__searchbar--fmQw3",
	"results": "src-containers-Admin-styles__results--26PbL",
	"create": "src-containers-Admin-styles__create--tR1D5",
	"icon-link": "src-containers-Admin-styles__icon-link--2df7g",
	"iconLink": "src-containers-Admin-styles__icon-link--2df7g"
};