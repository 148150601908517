import React, { Component } from "react"
import * as styles from './styles.scss'
import Category from "./components/Category"
import Item from "./components/Item"
import { PAGES } from '../../constants'

import api from "services/api"
import { UserPill } from "components/UserPill"

interface IProps {
    active: string
    setRoute: (route: string) => void
}

interface State {
    crawlingsWithoutGroups: string[]
}

class SideBar extends Component<IProps, State> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            crawlingsWithoutGroups: []
        }
    }

    render() {
        return (
            <div className={styles.sidebar}>
                {Object.keys(PAGES).map((k) =>
                    <Category value={k} key={k}>
                        {PAGES[k].map((p: string) => (
                            <Item
                                active={this.props.active.toLowerCase() === p.toLowerCase()}
                                value={p}
                                key={p}
                                onClick={() => this.props.setRoute(p)}
                            />
                        ))}
                    </Category>
                )}
                <UserPill />
            </div>
        )
    }

    async onLogout() {
        await api.logout()
        window.location.href = '/'
    }
}

export default SideBar
