
import firebase from 'firebase'
import 'firebase/auth'

export const FIREBASE_CONFIG = process.env.NODE_ENV == 'production' ? {
    apiKey: "AIzaSyABoQfvdJ5rJmeR816ZlOkEIQMaVCaEYuw",
    authDomain: "adalongprod.firebaseapp.com",
    databaseURL: "https://adalongprod.firebaseio.com",
    projectId: "adalongprod",
    storageBucket: "adalongprod.appspot.com",
    messagingSenderId: "571935369648",
    appId: "1:571935369648:web:9ce1ffd91a256a86"
} : {
    apiKey: "AIzaSyByVnrMEieYuVCD8GjdauvMXbxgOGkux4E",
    authDomain: "adalongdev.firebaseapp.com",
    databaseURL: "https://adalongdev.firebaseio.com",
    projectId: "adalongdev",
    storageBucket: "adalongdev.appspot.com",
    messagingSenderId: "849024363521",
    appId: "1:849024363521:web:64037b719467a4ab"
}

firebase.initializeApp(FIREBASE_CONFIG)

export default firebase