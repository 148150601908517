import React from 'react'
import _ from 'lodash'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from '../../services/firebaseAuth'
import store from '../../store'
import { setRoute } from 'services/tools';
import api from 'services/api';
import { loadAdminInfo } from 'actions/common';
import { PAGES } from '../../constants';
import Alert from 'components/Alert';

interface SignInSuccess {
    credential: {
        accessToken: string
        idToken: string
        providerId: string
        signInMethod: string
    }
    user: {
        uid: string
        email: string
        emailVerified: boolean
        phoneNumber?: string
        photoURL: string
    }
    operationType: string
    additionalUserInfo: any
}


export class UserLogin extends React.Component<{}, { errors: string[] }> {
    constructor(props: any) {
        super(props)
        this.state = {
            errors: []
        }
    }

    componentDidMount() {
        if (firebase.auth().currentUser) setRoute('/Users')
    }

    render() {
        const uiConfig = {
            signInFlow: 'popup',
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            ],
            callbacks: {
                'signInSuccessWithAuthResult': (authResult: SignInSuccess, _: string) => {
                    let user = firebase.auth().currentUser
                    if (authResult && user) {
                        // TODO: dispatch error for unverified email
                        if (!authResult.user.emailVerified) {
                            user.sendEmailVerification().then(() => {
                                this.state.errors.push('unverifiedEmail')
                            }, () => {
                                this.state.errors.push('cantSendEmail')
                            })
                        } else {
                            user.getIdToken().then(idToken => {
                                api.firebaseAuth({
                                    uid: authResult.user.uid,
                                    idToken,
                                    has_accepted_cgu: true
                                }).then(() => {
                                    loadAdminInfo()(store.dispatch).then(() => {
                                        setRoute(PAGES.Admin[0]);
                                    })
                                }).catch(err => {
                                    this.state.errors.push(err.message)
                                    firebase.auth().signOut().then(() => {
                                        setRoute('/login')
                                    })
                                })
                            })
                        }
                    }
                    return false
                }
            }
        }

        return <React.Fragment>
            {
                this.state.errors.map((error, idx) => <Alert
                    key={idx}
                    variant="error"
                    message={error}
                    onClose={() => {
                        this.setState({ errors: this.state.errors.filter(err => err != error) })
                    }} />
                )}
            {
                this.state.errors.length > 0 ?
                    null :
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            }
        </React.Fragment>
    }
} 