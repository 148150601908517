exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-App__app--2iKXm{display:flex;height:100%;background-color:#202c33;font-family:roboto;font-size:15px}.src-App__app--2iKXm .src-App__content--c2W3_{flex:1;color:#464646;padding:25px;background-color:#f9f9f9;border-left:2px solid #434e4e;overflow-y:auto;overflow-x:hidden}.src-App__app--2iKXm .src-App__content--c2W3_ .src-App__container--3qxJc{height:100%;width:100%}.src-App__app--2iKXm .src-App__sideBar--Xne_v{flex-shrink:0}html,body,body>div{height:100%;margin:0}body{font-family:\"-apple-system\",\"system-ui\",\"BlinkMacSystemFont\",\"Segoe UI\",\"Roboto\",\"Helvetica Neue\",\"Arial\",\"sans-serif\";font-size:1rem;font-weight:normal;line-height:1.5}", ""]);

// Exports
exports.locals = {
	"app": "src-App__app--2iKXm",
	"content": "src-App__content--c2W3_",
	"container": "src-App__container--3qxJc",
	"sideBar": "src-App__sideBar--Xne_v"
};