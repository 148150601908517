import React, { Component } from "react";
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import * as styles from './styles.scss'

import translation from './translation';
import LocalizedStrings from 'react-localization';
import { UserLogin } from "components/LoginForm";

let lang = new LocalizedStrings(translation);

interface IProps {

}

interface IState {
    loading: boolean
}

class Login extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    render() {
        return (
            <div className={styles.login}>
                <main className={styles.main}>
                    <Paper className={styles.paper}>
                        <Typography component="h1" variant="h5">
                            {lang.title}
                        </Typography>
                        <UserLogin />
                    </Paper>
                </main>
            </div>
        )
    }
}

export default connect()(Login);
