import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import store, {history} from "./store"
import {Route, Switch} from 'react-router-dom'
import {ConnectedRouter} from 'connected-react-router'

import Crawlings from 'containers/Crawlings'
import {Pipelines} from './containers/Pipeline'
import Login from './containers/Login'
import AppLoading from './containers/AppLoading'

import * as CommonActions from './actions/common'

import * as styles from './App.scss'
import SideBarContainer from 'containers/SideBarContainer'
import NotFound from 'containers/NotFound'
import {setRoute} from './services/tools'
import { PAGES } from './constants'
import Users from 'containers/Users'
import Groups from 'containers/Groups'
import { Administrators } from 'containers/Administrators'

declare let module: any

interface IProps {
    compiler: string,
    framework: string,
    bundler: string
}

class App extends Component<IProps> {
    constructor(props: any) {
        super(props);

        CommonActions.loadAdminInfo()(store.dispatch).then(() => {
            if (/^\/(login)?$/.test(window.location.pathname)) {
                setRoute(PAGES.Admin[0]);
            }
        }).catch(e => {
            console.error(e);
            setRoute('login');
        }).then(() => {
            store.dispatch(CommonActions.setAppLoaded());
        });
    }

    render() {
        return (
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <div className={styles.app}>
                        <AppLoading />
                        <div className={styles.sideBar}>
                            <SideBarContainer />
                        </div>
                        <div className={styles.content} onScroll={this.onScroll.bind(this)}>
                            <div className={styles.container}>
                                <Switch ref="router">
                                    <Route exact path="/login" component={Login} />

                                    <Route exact path="/users" component={Users} />
                                    <Route exact path="/administrators" component={Administrators} />
                                    <Route exact path="/groups" component={Groups} />
                                    <Route exact path="/crawling" component={Crawlings} />
                                    <Route exact path="/pipelines" component={Pipelines} />
                                    <Route path="/:page" component={NotFound} />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </ConnectedRouter>
            </Provider>
        )
    }

    onScroll(e: any) {
        let { target } = e;
        let scrollBottom = target.scrollHeight - target.scrollTop - target.clientHeight;
        window.dispatchEvent(new CustomEvent("scrollbottom", { detail: scrollBottom }))
    }
}


ReactDOM.render(
    <App compiler="Typescript" framework="React" bundler="Webpack" />,
    document.getElementById('root')
);

if (module.hot) {
    module.hot.accept();
}
