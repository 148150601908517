import React, { useEffect, useState } from 'react'
import { TableCell, TableRow, TextField } from '@material-ui/core'

import { Admin } from './Admin'
import api from '../services/api'
import { IAdmin } from 'common'

interface AdministratorEditDialogProps {
    onValidItem: (admin: Partial<IAdmin>) => void
    admin: Partial<IAdmin> | null
}

function AdministratorEditDialog(props: AdministratorEditDialogProps) {
    const [email, setEmail] = useState(props.admin?.email || "")
    const [username, setUsername] = useState(props.admin?.email || "")

    const setAdministratorValidity = () => {
        if (email.length > 1 && username.length > 1) {
            const newAdmin = {
                username,
                email,
            } as Partial<IAdmin>

            props.onValidItem(newAdmin)
        }
    }

    useEffect(setAdministratorValidity, [email, username])

    return <div style={{
            display: 'flex',
            flexDirection: 'column'
        }}>
        <TextField
            type='text'
            value={username}
            label='Username'
            margin='normal'
            placeholder='Jean Neige'
            onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
        type='email'
        value={email}
        label='Email'
        margin='normal'
        placeholder='john.doe@example.com'
        onChange={(e) => setEmail(e.target.value)}
    />
    </div>
}


export function Administrators() {
    const displayHeader =
        <TableRow>
            <TableCell>Username</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Creation Date</TableCell>
            <TableCell/>
        </TableRow>

    const create = (admin: Partial<IAdmin>): Promise<void> => {
        return api.createAdmin(admin)
    }


    const remove = (admin: Partial<IAdmin>): Promise<void> => {
        if (!admin._id) {
            return Promise.reject(new Error('Cannot delete user without an _id'))
        }
        return api.removeAdmin(admin._id)
    }

    const displayLine = (admin: Partial<IAdmin>): JSX.Element[] => {
        return [
            <TableCell key='1'>{admin.username}</TableCell>,
            <TableCell key='2'>{admin.email}</TableCell>,
            <TableCell key='3'>{admin.created_at}</TableCell>
        ]
    }

    const displayEditWindow = (admin: Partial<IAdmin> | null, onValidItem: (admin: Partial<IAdmin>) => void): JSX.Element => {
        return <AdministratorEditDialog
            onValidItem={onValidItem}
            admin={admin}
        />
    }

    const search = (input: string): Promise<Partial<IAdmin>[]> => {
        return api.getAdmins({ filters: { name: input }, options: {} })
            .then((res) => res.admins)
    }

    return <Admin<Partial<IAdmin>>
        title='Administrators'
        displayHeader={displayHeader}
        create={create}
        search={search}
        remove={remove}
        displayEditWindow={displayEditWindow}
        getId={(admin) => admin._id!}
        displayLine={displayLine}
    />
}
