import React, { useEffect, useState } from 'react'
import {
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel, FormGroup,
    FormLabel,
    TableCell,
    TableRow,
    TextField
} from '@material-ui/core'

import { IGroup } from 'common'
import { Admin } from '../Admin'
import api from '../../services/api'
import styles from "./styles.scss"
import LocalizedStrings from "react-localization"
import { BodyCreateGroup } from "common/dist/types/admin"

type EditableGroup = Pick<IGroup, 'name' | 'terms_url' | 'feature_flags' | 'tags'> & { _id?: string }

interface GroupEditDialogProps {
    onValidItem: (group: Partial<EditableGroup> | null) => void
    group: EditableGroup | null
}

function GroupEditDialog(props: GroupEditDialogProps) {
    const [name, setName] = useState(props.group?.name || "")
    const initialFeatureFlags = props.group?.feature_flags || {
        share: false,
        widget: false,
        create: false,
        product_catalog: false,
        text_media: false,
        hide_old_ugc: false,
        hide_unavailable_ugc: false,
        crm: false,
    }
    const [featureFlags, setFeatureFlags] = useState(initialFeatureFlags)
    const [termsUrl, setTermsUrl] = useState(props.group?.terms_url || "")

    const isValidUrl = (str: string | null): boolean => {
        if(!str){
            return true;
        }
        try {
            new URL(str)
        } catch {
            return false
        }
        return true
    }

    const setGroupValidity = () => {
        if (name.length > 1 && isValidUrl(termsUrl)) {
            const newGroup = {
                ...props.group,
                name,
                feature_flags: featureFlags,
                terms_url: termsUrl,
            }

            props.onValidItem(newGroup)
        } else {
            props.onValidItem(null)
        }
    }

    useEffect(setGroupValidity, [name, featureFlags, termsUrl])

    // we don't want to allow name editing if it's already set
    const isNameEditingDisabled = !!props.group && props.group.name.length > 1

    const changeFeatureFlag = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        const ff = e.target.name

        setFeatureFlags({
            ...featureFlags,
            [ff]: checked,
        })
    }

    return <div className={styles.form}>
        <TextField
            type='text'
            value={name}
            label='Name'
            margin='normal'
            placeholder='adalong'
            disabled={isNameEditingDisabled}
            required={true}
            onChange={(e) => setName(e.target.value)}
        />
        <TextField
            type='text'
            value={termsUrl}
            label='Terms URL'
            margin='normal'
            placeholder='http://www.example.com/terms'
            onChange={(e) => setTermsUrl(e.target.value)}
        />
        <FormControl>
            <FormLabel>
                Feature Flags
            </FormLabel>
            <FormGroup>
                {Object.entries(featureFlags).map(([ff, checked]) => {
                    return <FormControlLabel
                        key={ff}
                        control={<Checkbox
                            checked={checked}
                            name={ff}
                            onChange={changeFeatureFlag}
                        />}
                        label={lang.placeholder.featureFlags[ff as keyof IGroup['feature_flags']]}
                    />
                })}
            </FormGroup>
        </FormControl>
    </div>
}

function Groups() {
    const [refreshIndex, setRefreshIndex] = useState(0)
    const displayHeader =
        <TableRow>
            <TableCell style={{ minWidth: 80 }}>Name</TableCell>
            <TableCell>Tags</TableCell>
            <TableCell style={{ minWidth: 100 }}>Add #</TableCell>
            <TableCell style={{ minWidth: 70 }} />
        </TableRow>

    const update = async (group: Partial<EditableGroup>): Promise<void> => {
        if (!group._id) {
            return Promise.reject(new Error('Cannot update group without an _id'))
        }

        const payload = toPayload(group)
        await api.updateGroup(group._id, payload)
    }

    const addTagToGroup = async (tag: string, group: EditableGroup) => {
        await update({
            _id: group._id,
            tags: [...group.tags, tag]
        })
        setRefreshIndex(refreshIndex + 1)
    }

    const removeTagFromGroup = async (tag: string, group: EditableGroup) => {
        await update({
            _id: group._id,
            tags: group.tags.filter(t => t !== tag)
        })
        setRefreshIndex(refreshIndex + 1)
    }

    const create = async (group: Partial<EditableGroup>): Promise<void> => {
        const payload = toPayload(group)
        await api.createGroup(payload)
    }

    const search = (input: string): Promise<EditableGroup[]> => {
        return api.getGroups({ filters: { name: input }, options: { limit: 1000 } })
            .then((res) => res.groups)
    }

    const remove = (group: EditableGroup): Promise<void> => {
        if (!group._id) {
            return Promise.reject(new Error('Cannot delete group without an _id'))
        }
        return api.deleteGroup(group._id)
    }

    const displayLine = (group: EditableGroup): JSX.Element[] => {
        return [
            <TableCell
                key={group._id}
            >
                {group.name}
            </TableCell>,
            <TableCell
                key={group._id + '_tag'}
            >
                {group.tags.map(tag => {
                    const handleDelete = () => {
                        removeTagFromGroup(tag, group)
                    }
                    return <Chip
                        label={tag}
                        onDelete={handleDelete}
                        variant="outlined"
                    />
                })}
            </TableCell>,
            <TableCell>
                <TextField
                    id="outlined-basic"
                    label="hashtag"
                    variant="outlined"
                    
                    onKeyPress={e => {
                        if (e.charCode == 13) {
                            addTagToGroup((e.target as any).value, group);
                            (e.target as any).value = ''
                        }
                    }} />
            </TableCell>
        ]
    }

    const displayEditWindow = (group: EditableGroup | null, onValidItem: (group: Partial<EditableGroup> | null) => void): JSX.Element => {
        return <GroupEditDialog
            onValidItem={onValidItem}
            group={group}
        />
    }

    return <Admin<EditableGroup>
        title='Groups'
        displayHeader={displayHeader}
        create={create}
        update={update}
        search={search}
        remove={remove}
        refreshIndex={refreshIndex}
        getId={(group) => group._id!}
        displayLine={displayLine}
        displayEditWindow={displayEditWindow}
    />
}

const toPayload = (g: Partial<EditableGroup>): BodyCreateGroup => {
    return {
        name: g.name!,
        termsUrl: g.terms_url,
        featureFlags: g.feature_flags,
        tags: g.tags || [],
    }
}

export default Groups

const lang = new LocalizedStrings({
    en: {
        placeholder: {
            featureFlags: {
                share: "Give access to Share module",
                widget: "Give access to Widget module",
                create: "Give access to Create module",
                product_catalog: "Give access to Product Filter, Product Linking and Product Widget features",
                text_media: "Can scrape and display plain text Tweets",
                hide_old_ugc: "Hide old UGC(> 1 year) from widgets",
                hide_unavailable_ugc: "Hide unavailable UGC from widgets",
                crm: "CRM",
            },
        },
    },
})
