import React, {useEffect, useState} from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Link,
    Paper, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core'

import * as styles from './styles.scss'
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import {Alert} from "@material-ui/lab"

interface AdminProps<T> {
    // CRUD methods
    search(input: string): Promise<T[]>
    // controls will appear iff the corresponding function is defined
    create?: (t: Partial<T>) => Promise<void>
    update?: (t: Partial<T>) => Promise<void>
    remove?: (t: T) => Promise<void>

    // The resource table header
    // A TableRow is expected
    displayHeader: JSX.Element

    // A resource line. onDelete needs to be called when the user wants to delete a resource
    // A TableRow is expected
    displayLine(t: T): JSX.Element[]
    getId(t: T): string

    // The resource editing window that appears on creation or update
    // It takes an instance of an item in case of update, and null in case of creation
    // onValidItem needs to be called each time the display window represents a valid item that can potentially be
    // saved. This commands the enabling/disabling of the save button.
    // onValidItem should be called each time a new valid item is produced in the window
    displayEditWindow?(t: T | null, onValidItem: (t: Partial<T> | null) => void): JSX.Element
    refreshIndex?: number

    title: string
}

const searchDebounceDelayMillis = 100

export function Admin<T>(props: AdminProps<T>) {
    // The search input
    const [search, setSearch] = useState("")
    // The list of resources that is displayed
    const [list, setList] = useState<T[]>([])
    const [isEditionVisible, setIsDialogVisible] = useState(false)
    // The "Are you sure you want to delete?" dialog visibility
    const [isConfirmationVisible, setIsConfirmationVisible] = useState(false)
    // The item that is currently being edited, as represented in the backend
    const [currentItem, setCurrentItem] = useState<T | null>(null)
    // The item that is currently being edited, with unsaved modifications
    const [newItem, setNewItem] = useState<Partial<T> | null>(null)
    // Timer that controls search debounce
    const [searchTimeout, setSearchTimeout] = useState<number | null>(null)
    const [isErrorVisible, setIsErrorVisible] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [isSuccessVisible, setIsSuccessVisible] = useState(false)
    const [successMessage, setSuccessMessage] = useState("")

    useEffect(() => {
        performSearch("")
    }, [ props.refreshIndex ])

    const displayError = (msg: string): void => {
        setIsErrorVisible(true)
        setErrorMessage(msg)
    }

    const displaySuccess = (msg: string): void => {
        setIsSuccessVisible(true)
        setSuccessMessage(msg)
    }

    // onSearch implements a simple debounce logic:
    // On input, a timer is launched, after which the search is performed
    // On subsequent inputs, the timer is reset
    const onSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const input = e.target.value
        setSearch(input)

        if (searchTimeout) {
            clearTimeout(searchTimeout)
        }

        const timer = setTimeout(function() {
            setSearchTimeout(null)
            performSearch(input)
        }, searchDebounceDelayMillis)
        setSearchTimeout(timer as unknown as number) // this is wild, it seems that a timer only compiles if it's cast as number
    }

    const performSearch = (input: string): Promise<void> => {
        return props.search(input)
            .then((ts) => setList(ts))
            .catch(e => {
                console.error(e)
                displayError(`Error performing a search: ${JSON.stringify(e)}`)
            })
    }

    const onCreate = (): void => {
        setCurrentItem(null)
        setIsDialogVisible(true)
    }

    const onEdit = (t: T): void => {
        setCurrentItem(t)
        setIsDialogVisible(true)
    }

    const closeEdition = (): void => {
        setCurrentItem(null)
        setNewItem(null)
        setIsDialogVisible(false)
    }

    const onDelete = (t: T): void => {
        setIsConfirmationVisible(true)
        setCurrentItem(t)
    }

    const onConfirmDelete = (): Promise<void> => {
        if (!props.remove) {
            return Promise.reject("Remove function should be defined")
        }

        return props
          .remove(currentItem!)
          .then(() => performSearch(search))
          .then(() => displaySuccess("Deletion successful!"))
          .catch((e) => {
            console.error("Item deletion error", e);
            displayError(`Deletion error: ${e.message}`);
          })
          .then(closeConfirmation);
    }

    const closeConfirmation = (): void => {
        setCurrentItem(null)
        setIsConfirmationVisible(false)
    }

    const onValidNewItem = (t: Partial<T> | null): void => {
        setNewItem(t)
    }

    const onSave = (): Promise<void> => {
        const save = currentItem ? props.update : props.create
        if (!save) {
            return Promise.reject("save function should be defined")
        }

        return save(newItem!)
            .then(() => performSearch(search))
            .catch((e) => {
                console.error("Item creation error", e)
                displayError(`Creation or update error: ${JSON.stringify(e)}`)
            })
            .then(() => setIsDialogVisible(false))
            .then(() => displaySuccess("Save successful!"))
    }

    const editButton = (t: T): JSX.Element => {
        return <Link
            className={styles.iconLink}
            onClick={() => onEdit(t)}
        >
            <EditIcon/>
        </Link>
    }

    const deleteButton = (t: T): JSX.Element => {
        return <Link
            className={styles.iconLink}
            onClick={() => onDelete(t)}
        >
            <DeleteIcon/>
        </Link>
    }

    return <div>
        <Paper
            className={styles.paper}
        >
            <Typography variant="h2">
                {props.title}
            </Typography>
            <div
                className={styles.searchbar}
            >
                <TextField
                    placeholder="Search"
                    value={search}
                    onChange={onSearch}
                >
                </TextField>
            </div>
            {props.create && <div className={styles.create}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onCreate}
                >
                    Create
                </Button>
            </div>}

            <Typography component="p" className={styles.results}>
                <i>{list.length} results</i>
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        {props.displayHeader}
                    </TableHead>
                    <TableBody>
                        {list.map(t => <TableRow key={props.getId(t)}>
                                {props.displayLine(t)}
                                <TableCell
                                    key="last-cell"
                                >
                                    {props.update && editButton(t)}
                                    {props.remove && deleteButton(t)}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                onClose={closeEdition}
                open={isEditionVisible}>
                <DialogContent>
                    {props.displayEditWindow && props.displayEditWindow(currentItem, onValidNewItem)}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onSave}
                        variant="contained"
                        startIcon={<EditIcon/>}
                        disabled={!newItem}
                        color="primary">
                        Save
                    </Button>
                    <Button
                        onClick={closeEdition}
                        variant="contained"
                        color="default">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isConfirmationVisible}
                onClose={closeConfirmation}
            >
                <DialogTitle>
                    Confirmation
                </DialogTitle>
                <DialogContent>
                    Are you sure you want to remove this user?
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onConfirmDelete}
                        variant="contained"
                        startIcon={<DeleteIcon/>}
                        color="secondary">
                        Yes
                    </Button>
                    <Button
                        onClick={closeConfirmation}
                        variant="contained"
                        color="default">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={isErrorVisible}
                onClose={() => setIsErrorVisible(false)}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                >
                <Alert
                    severity="error"
                    variant="filled"
                    >
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={isSuccessVisible}
                onClose={() => setIsSuccessVisible(false)}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                autoHideDuration={1000}
            >
                <Alert
                    severity="success"
                    variant="filled"
                >
                    {successMessage}
                </Alert>
            </Snackbar>
        </Paper>
    </div>
}
