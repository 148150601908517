import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
const reduxLogger = require('redux-logger');

import common, { TState as CommonState } from './reducers/common';
import scraping, { TState as ScrapingState } from './reducers/common';


export const history = createBrowserHistory({
  basename: '/',
});

const middleware = [
  thunk,
  routerMiddleware(history),
];

if (process.env.BROWSER) {
  /* eslint-disable global-require */

  const logger = reduxLogger({
    collapsed: true,
  });
  middleware.push(logger);
}

let store = createStore(
  combineReducers({
    router: connectRouter(history),
    common,
    scraping,
  }),
  compose(applyMiddleware(
    ...middleware
  ))
);

export default store;

export type TStoreState = {
  common: CommonState,
  scraping: ScrapingState,
  router: RouterState
}

export type DispatchType = (any: any) => any | any